import Page1 from '../pages/Page1';
import Page2 from '../pages/Page2';
import Page3 from '../pages/Page3';
import Page4 from '../pages/Page4';
import Page5 from '../pages/Page5';
import Confirmation from '../pages/Confirmation';
import Lookup from '../pages/Lookup';
import QuickStart from '../pages/QuickStart';

import ContractMichigan from '../pages/ContractMichigan';
import ContractOhio from '../pages/ContractOhio';

const routes = [
    {
        path: "/confirmation",
        component: Confirmation,
    },
    {
        path: "/5",
        component: Page5,
    },
    {
        path: "/4",
        component: Page4,
    },
    {
        path: "/3",
        component: Page3,
    },
    {
        path: "/2",
        component: Page2,
    },
    {
        path: "/1",
        component: Page1,
    },
    {
        path: "/lookup",
        component: Lookup,
    },
    {
        path: "/quick-start",
        component: QuickStart,
    },
    {
        path: "/representation-agreement-michigan",
        component: ContractMichigan,
    },
    {
        path: "/representation-agreement-ohio",
        component: ContractOhio,
    },
    {
        path: "",
        component: Page1,
    },

]

export default routes;