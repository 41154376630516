import { Redirect } from 'react-router-dom';
import { useState, useEffect , useCallback} from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import Circle from 'components/markup/loading/Circle'

import { setProgress } from 'store/functions/system/system';
import { toggleStandardLoader } from 'store/functions/system/system';

import _pages from '_functions/pages';
import _billing from '_functions/billing';

import Overview from './Overview';
import Payment from './Payment';

const Page5 = ({form}) => {

    const [redirect] = useState(form.form && form.contact_id ? null : '/1');
    const [total, setTotal] = useState(0);
    const [view, setView] = useState('overview');
    const [discount, setDiscount] = useState(form.discount);
    const [discountAmount, setDiscountAmount] = useState(form.discount_amount);
    const [err, setErr] = useState(false);
    const [paymentErr, setPaymentErr] = useState(false);
    const [payformUrl, setPayformUrl] = useState(false);
    const [paymentLoaded, setPaymentLoaded] = useState(false);

    const onSetView = (view) => {
        window.scrollTo(0,0)
        setView(view)
    }

    const onAddDiscount = (discount) => {
        const amount = parseInt(total) * (1 - (discount / 100));
        const discountAmount = parseInt(total) * (discount / 100);
        setDiscount(amount.toFixed(2));
        setDiscountAmount(discountAmount.toFixed(2));
    }

    const getTotal = useCallback(async () => {

        const total = await _pages.getTotal(form.form);
        if(total.data) {
            setTotal(total.data)
        } else {
            setErr(true)
        }

    }, [form.form])

    const getFortisContact = useCallback(async () => {
        const contact = await _billing.getContact(form.contact_id);
        if(contact.data && contact.data.contact) {
            const payform = await _billing.getPayForm(form.contact_id)
            if(payform.data) {
                setPayformUrl(payform.data);
                setPaymentLoaded(true);
                toggleStandardLoader(false)
            } else {
                setPaymentErr(true);
            }
        } else {
            setPaymentErr(true);
        }
    }, [form.contact_id])

    const onPaymentErr = () => {
        setPayformUrl(false);
        setPaymentLoaded(false);
        getFortisContact();
    }

    useEffect(() => {
        setProgress({page: 5, percent: 95});
        getTotal();
        getFortisContact();
    }, [getTotal, getFortisContact])

    const finalCost = discount ? discount : total;

    if(redirect) return <Redirect to={redirect} />
    if(!total) return <div className="py-6"><Circle /></div>
    if(err) return <Container><div className="alert alert-danger mt-5">Please try again in a few minutes</div></Container>

    return (
        <div id="page-5" className="page py-4 py-md-6 bg-white">
            
            {view === 'overview' ? (
                <Overview 
                    form={form}
                    total={total}
                    discount={discount}
                    discountAmount={discountAmount}
                    onAddDiscount={onAddDiscount}
                    setView={onSetView}
                />
            ) : (
                <Payment 
                    finalCost={finalCost}
                    paymentLoaded={paymentLoaded}
                    paymentErr={paymentErr}
                    payformUrl={payformUrl}
                    view={view}
                    onPaymentErr={onPaymentErr}
                />
            )}
         
        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
    };
};

export default connect(mapStateToProps, '')(Page5);