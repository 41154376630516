import { architeckCall } from 'database';

const apiWorker = (method, url, data, shouldNotShowAndLogError, headers = {}) => {
    return new Promise (async resolve => {

        // if the query param in any worker call is undefined just remove it
        if(url.slice(url.length -  9) === 'undefined') {
            url = url.slice(0, url.length -  9)
        }

        const call =  architeckCall({
            method,
            url,
            headers,
            data,
            shouldNotShowAndLogError: shouldNotShowAndLogError ? true : false
        })

        resolve(call)

    })
}

export default apiWorker;