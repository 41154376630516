import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import Circle from 'components/markup/loading/Circle';

import { getUrlParameter } from 'utils/urls';
import { setForm } from 'store/functions/form/form';

import _pages from '_functions/pages';
import Cookies from 'universal-cookie';

const Page1 = () => {

    const [redirect, setRedirect] = useState(false);
    
    const onEnter = useCallback(async () => {

        const state = {
            given_name: getUrlParameter('given_name'),
            family_name: getUrlParameter('family_name'),
            email: getUrlParameter('email'),
            phone: getUrlParameter('phone'),
            lead_source: getUrlParameter('lead_source'),
        }

        const saved = await _pages.savePage1(state)
      
        if(saved.success) {
            const cookies = new Cookies();
            cookies.set('contact_id', saved.data._id)
            setForm({...state, contact_id: saved.data._id})

            setRedirect('/lookup')
        } else {
            setRedirect('/1')

        }
       
    }, [])

    useEffect(() => {
        onEnter()
    }, [onEnter])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div id="page-1" className="page py-6">
            <Circle />
        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
    };
};

export default connect(mapStateToProps, '')(Page1);