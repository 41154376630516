import apiWorker from '../apiWorker'

const pages = {
    getDiscount : (code)                      => apiWorker('get', `/v1/discount_code/${code}`),
    getTotal    : (form_id)                   => apiWorker('get', `/v1/total/${form_id}`),
    getSettings : ()                          => apiWorker('get', `/v1/settings`),
    findContact : (contact_id)                => apiWorker('get', `/v1/lookup/${contact_id}`),
    lookup      : (contact_id, ticket_number) => apiWorker('get', `/v1/lookup/${contact_id}/${ticket_number}`),
    savePage1   : (data)                      => apiWorker('post', `/v1/page/1`, data),
    savePage3   : (data)                      => apiWorker('post', `/v1/page/3`, data),
    savePage5   : (data)                      => apiWorker('post', `/v1/page/5`, data),
}

export default pages;