import { useEffect } from 'react'
import Cookies from 'universal-cookie';
import { Container } from 'reactstrap';

const Confirmation = () => {

    // clear cookies
    useEffect(() => {
        const cookies = new Cookies();
        cookies.remove('contact_id');
        cookies.remove('ticket_number');
    }, [])

    return (
        <div className="py-4 py-md-6">
            <Container>
                <h1 className="display-1">ALL SET!</h1>
                <p>Your ticket has been submitted for review and one of our Roadside Specialists will be in touch shortly.</p>
                <p>Please note your card will not be charged unless your ticket is accepted.</p>
            </Container>
        </div>
    )

}

export default Confirmation;