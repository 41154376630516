import React, { Component } from 'react';
import Circle from "components/markup/loading/Circle";
import { connect } from 'react-redux';

class StandardLoader extends Component {


    state = {
        standardLoader: false,
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        this.setState({standardLoader: nextProps.standardLoader})
        
        if(nextProps.standardLoader) {
            document.body.classList.add('noScroll')
        } else {
            document.body.classList.remove('noScroll')
        }

    }

    render() {

        const { standardLoader } = this.state;

        if(standardLoader) {

            return (
                <div 
                    id="AosFader"
                    className={`top-0 bottom-0 right-0 left-0 position-fixed d-flex ${standardLoader !== true ? standardLoader : ''}`}
                    style={{zIndex: 9999999999999, background: 'rgba(0,0,0,.5)'}}
                >
                    <div className="align-self-center ml-auto mr-auto">
                        <Circle />
                    </div>
                </div> 
            )

        } else {

            return <div></div>

        }

    }

}

const mapStateToProps = state => {
    return {
        standardLoader: state.system.standardLoader,
    };
};
  
export default connect(mapStateToProps, '')(StandardLoader);