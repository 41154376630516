import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { Container, Input, FormGroup } from 'reactstrap';

import { setProgress } from 'store/functions/system/system';
import { toggleStandardLoader } from 'store/functions/system/system';
import { setForm } from 'store/functions/form/form';

import _pages from '_functions/pages';

import Addition from './Addition'

const Page3 = ({form}) => {

    const [apiError, setApiError] = useState(null);
    const [redirect, setRedirect] = useState(form.form && form.contact_id ? null : '/1');
    const [state, setState] = useState(JSON.parse(JSON.stringify({
        accident                          : form.accident,
        commercial                        : form.commercial,
        misd_checked                      : form.misd_checked,
        misd_checked_number               : form.misd_checked_number,
        suspended_license                 : form.suspended_license,
        suspended_license_knowledge       : form.suspended_license_knowledge,
        suspended_license_reinstatement   : form.suspended_license_reinstatement,
        cited_for_no_insurance            : form.cited_for_no_insurance,
        had_valid_insurance               : form.had_valid_insurance,
        had_valid_insurance_in_car        : form.had_valid_insurance_in_car,
        reckless_driving                  : form.reckless_driving,
        drunk_driving                     : form.drunk_driving,
        drunk_driving_prior               : form.drunk_driving_prior,
    })));
    const [errors, setErrors] = useState([])

    const onInputChange = (field, e, value) => {
        const newState = JSON.parse(JSON.stringify(state));

        if(value) {
            newState[field] = value;
        } else {
            newState[field] = e.target.value;
        }

        setState(newState)
    }

    const getErr = (err) => {
        return errors.includes(err) ?  <label className="text-danger">Required *</label> : null
    }

   
    const onSubmit = async () => {
        const errs = [];

        let requiredFields = ['accident', 'commercial', 'misd_checked'];

        if(state['misd_checked'] === 'yes') {
            requiredFields = requiredFields.concat('misd_checked_number', 'suspended_license', 'cited_for_no_insurance', 'reckless_driving', 'drunk_driving')
        }
        if(state['suspended_license'] === 'yes') {
            requiredFields = requiredFields.concat('suspended_license_knowledge', 'suspended_license_reinstatement')
        }
        if(state['cited_for_no_insurance'] === 'yes') {
            requiredFields = requiredFields.concat('had_valid_insurance', 'had_valid_insurance_in_car')
        }
        if(state['drunk_driving'] === 'yes') {
            requiredFields = requiredFields.concat('drunk_driving_prior')
        }

        requiredFields.forEach(field => {
            if(!state[field] || state[field] === 'false') errs.push(field)
        })

        setErrors(errs);

        if(errs.length) return;

        toggleStandardLoader(true);
        const saved = await _pages.savePage3({...state, form: form.form})
        toggleStandardLoader(false);

        if(!saved.success) {
            return setApiError(true)
        }
        
        setForm(state)
        setRedirect('/4')
    }



    useEffect(() => {
        setProgress({page: 3, percent: 50})
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div id="page-3" className="page py-4 py-md-6">
            <Container>

                <div className="alert alert-warning mb-4 mb-md-6"><i className="fas fa-clipboard-list mr-2 " />
                On average, over <b className="text-underline">100,000</b> traffic tickets are issued daily.</div>

                <FormGroup>
                    {getErr('accident')}
                    <h2 className="display-1 mb-3 w-100">Was There An Accident? <span className="star text-danger">*</span></h2>
                    <Input 
                        type="select"
                        value={state.accident}
                        onChange={(e) => onInputChange('accident', e)}
                        placeholder="Ticket Number"
                    >
                        <option value="false"></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Input>

                    <Addition state={state} field={'accident'} />
                </FormGroup>

                <hr />
                
                <FormGroup>
                    {getErr('commercial')}
                    <h2 className="display-1 mb-3 w-100">Is This A Commercial Ticket (Truck Drivers, Etc.)? <span className="star text-danger">*</span></h2>
                    <Input 
                        type="select"
                        value={state.commercial}
                        onChange={(e) => onInputChange('commercial', e)}
                        placeholder="Ticket Number"
                    >
                        <option value="false"></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Input>

                    <Addition state={state} field={'commercial'} />
                </FormGroup>

                <hr />
                
                <FormGroup>
                    {getErr('misd_checked')}
                    <h2 className="display-1 mb-3 w-100">In The Middle Of Your Ticket It States What Your Ticket Is For. Did The Police Officer Check The Box Called “Misd“ On Any Of The Tickets? <span className="star text-danger">*</span></h2>
                    <Input 
                        type="select"
                        value={state.misd_checked}
                        onChange={(e) => onInputChange('misd_checked', e)}
                        placeholder="Ticket Number"
                    >
                        <option value="false"></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Input>

                </FormGroup>

                {state.misd_checked === 'yes' ? (
                    <div>

                        <hr />

                        <FormGroup>
                            {getErr('misd_checked_number')}
                            <h2 className="display-1 mb-3 w-100">How Many Times Did The Officer Do That? <span className="star text-danger">*</span></h2>
                            <Input 
                                type="select"
                                value={state.misd_checked_number}
                                onChange={(e) => onInputChange('misd_checked_number', e)}
                                placeholder="Ticket Number"
                            >
                                <option value="false"></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </Input>

                            <Addition state={state} field={'misd_checked'} />
                            {state.misd_checked_number && state.misd_checked_number !== 'false' && state.misd_checked_number !== '0' ? (
                                <p>You must appear with us on the date(s) of your hearing(s) since there is a misdemeanor charge on your ticket; our lawyers will vigorously represent you and meet with the prosecutor; but you must be present in court with us on those dates. </p>
                            ) : null}
                        </FormGroup>

                        {parseInt(state.misd_checked_number) > 0 ? (

                            <div>

                                <hr />
                                <FormGroup>
                                    {getErr('suspended_license')}
                                    <h2 className="display-1 mb-3 w-100">Does This Ticket Cite You For A Suspended License? <span className="star text-danger">*</span></h2>
                                    <Input 
                                        type="select"
                                        value={state.suspended_license}
                                        onChange={(e) => onInputChange('suspended_license', e)}
                                        placeholder="Ticket Number"
                                    >
                                        <option value="false"></option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>

                                    {state.suspended_license === 'yes' ? (
                                        <>
                                        <Addition state={state} field={'suspended_license'} />

                                        <p>It is important that we fight this ticket for you because if you do not fight it, it carries at least $200 in court fines, and $1000 in Drivers Fees to the State of Michigan, and up to 90 days in jail (rare) and up to 24 months on probation. So it is important that we work together to fight this ticket! And if your license is suspended or revoked, our firm can help you reinstate it; this requires an appeal to the DAAD department of the State of Michigan or to the Circuit Court; please call us immediately to learn more</p>
                                        </>
                                    ) : null}
                                </FormGroup>

                                {state.suspended_license === 'yes' ? (

                                    <div>

                                        <hr />

                                         <FormGroup>
                                            {getErr('suspended_license_knowledge')}
                                            <h2 className="display-1 mb-3 w-100">If Yes, Did You Know It Was Suspended? <span className="star text-danger">*</span></h2>
                                            <Input 
                                                type="select"
                                                value={state.suspended_license_knowledge}
                                                onChange={(e) => onInputChange('suspended_license_knowledge', e)}
                                                placeholder="Ticket Number"
                                            >
                                                <option value="false"></option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Input>
                                        </FormGroup>

                                        <hr />

                                        <FormGroup>
                                            {getErr('suspended_license_reinstatement')}
                                            <h2 className="display-1 mb-3 w-100">Is Your License Eligible For Reinstatement Now? <span className="star text-danger">*</span></h2>
                                            <Input 
                                                type="select"
                                                value={state.suspended_license_reinstatement}
                                                onChange={(e) => onInputChange('suspended_license_reinstatement', e)}
                                                placeholder="Ticket Number"
                                            >
                                                <option value="false"></option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                                <option value="unknown">I Don't Know</option>
                                            </Input>

                                            {state.suspended_license_reinstatement === 'yes' ? (
                                                <p className="mt-6">In order to find out if you are eligible to reinstate your license, you must go to the Secretary of State before your hearing, and ask them if you are eligible.</p>
                                            ) : null}
                                        </FormGroup>

                                    </div>

                                ) : null}

                                <hr />

                                <FormGroup>
                                    {getErr('cited_for_no_insurance')}
                                    <h2 className="display-1 mb-3 w-100">Does Ticket Cite You For No Insurance/No Proof Of Insurance? <span className="star text-danger">*</span></h2>
                                    <Input 
                                        type="select"
                                        value={state.cited_for_no_insurance}
                                        onChange={(e) => onInputChange('cited_for_no_insurance', e)}
                                        placeholder="Ticket Number"
                                    >
                                        <option value="false"></option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>
                                </FormGroup>

                                {state.cited_for_no_insurance === 'yes' ? (

                                    <div>

                                        <hr />
                                        <FormGroup>
                                            {getErr('had_valid_insurance')}
                                            <h2 className="display-1 mb-3 w-100">Did You Have Valid Insurance At The Time? <span className="star text-danger">*</span></h2>
                                            <Input 
                                                type="select"
                                                value={state.had_valid_insurance}
                                                onChange={(e) => onInputChange('had_valid_insurance', e)}
                                                placeholder="Ticket Number"
                                            >
                                                <option value="false"></option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Input>
                                        </FormGroup>

                                        <hr />

                                        <FormGroup>
                                            {getErr('had_valid_insurance_in_car')}
                                            <h2 className="display-1 mb-3 w-100">Did You Have It In The Car Somewhere At The Time? <span className="star text-danger">*</span></h2>
                                            <Input 
                                                type="select"
                                                value={state.had_valid_insurance_in_car}
                                                onChange={(e) => onInputChange('had_valid_insurance_in_car', e)}
                                                placeholder="Ticket Number"
                                            >
                                                <option value="false"></option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Input>
                                        </FormGroup>
                                    </div>

                                ) : null}

                                <hr />

                                <FormGroup>
                                    {getErr('reckless_driving')}
                                    <h2 className="display-1 mb-3 w-100">Was There A Reckless Driving Charge On Your Ticket? <span className="star text-danger">*</span></h2>
                                    <Input 
                                        type="select"
                                        value={state.reckless_driving}
                                        onChange={(e) => onInputChange('reckless_driving', e)}
                                        placeholder="Ticket Number"
                                    >
                                        <option value="false"></option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>

                                    <Addition state={state} field={'reckless_driving'} />

                                    {state.reckless_driving === 'yes' ? (
                                        <p><b className="text-danger">WARNING:</b> It is important that we fight this ticket for you because if you do not fight it, It carries an AUTOMATIC SUSPENSION of your license for 6 months, and at least $400 in total fines to the city and at least $1000 in fines to the state, and up to 90 days in jail (rare) and up to 24 months on probation. So it is important that we work together to fight this ticket! </p>
                                    ): null}
                                </FormGroup>
                                
                                <hr />

                                <FormGroup>
                                    {getErr('drunk_driving')}
                                    <h2 className="display-1 mb-3 w-100">Was There A Drunk Driving (OWI) On Your Ticket? <span className="star text-danger">*</span></h2>
                                    <Input 
                                        type="select"
                                        value={state.drunk_driving}
                                        onChange={(e) => onInputChange('drunk_driving', e)}
                                        placeholder="Ticket Number"
                                    >
                                        <option value="false"></option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>

                                    <Addition state={state} field={'drunk_driving'} />
                                    {state.drunk_driving === 'yes' ? (
                                        <p><b className="text-danger">WARNING:</b> It is important that we fight this ticket for you because if you do not fight it, it carries an AUTOMATIC SUSPENSION of your license for 30 days and a restriction on your license for 5 months, and at least $900 in total fines to the city and at least $2000 in fines to the state, and up to 90 days in jail and up to 24 months on probation. So it is important that we work together to fight this ticket! </p>
                                    ) : null}
                                </FormGroup>

                                {state.drunk_driving === 'yes' ? (

                                    <div>
                                        <hr />

                                        <FormGroup>
                                            {getErr('drunk_driving_prior')}
                                            <h2 className="display-1 mb-3 w-100">Have You Had Any Drunk Driving Ticket In Your Past? <span className="star text-danger">*</span></h2>
                                            <Input 
                                                type="select"
                                                value={state.drunk_driving_prior}
                                                onChange={(e) => onInputChange('drunk_driving_prior', e)}
                                                placeholder="Ticket Number"
                                            >
                                                <option value="false"></option>
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Input>

                                            <Addition state={state} field={'drunk_driving_prior'} />
                                        </FormGroup>

                                    </div>

                                ) : null}

                            </div>

                        ) : null} 
                        {/*  end check number */}

                    </div>
                ) : null}

                <hr />

                {apiError ? (
                    <div className="alert alert-warning">Please try again in a few minutes</div>
                ): null}

                <div className="text-right">
                    <button onClick={onSubmit} className="btn btn-yellow">SUBMIT AND CONTINUE</button>
                </div>

            </Container>
         
        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
    };
};

export default connect(mapStateToProps, '')(Page3);