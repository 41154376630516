import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { calculateMisdemeanor } from '../_calculations'
import { formatMoney } from 'utils/currency';

const Page4Overview = ({form, additions, courts, total, discount, discountAmount, setView}) => {

    const court = courts.find(c => c.name === form.court);

    return (
        <div>
            <Container>

                <div className="alert alert-success mb-4 mb-md-6"><i className="fas fa-check mr-2 " />
                    We're almost ready to <span className="text-underline font-weight-bold">fight your ticket</span>.
                </div>

                <h1 className="mb-4 mb-md-6">Payment Summary</h1>

                <p className="mb-5">Enter your payment information below and one of Roadside Experts will reach out to you shortly.</p>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="font-weight-bold text-italic text-dark" style={{fontSize: 20}}>TICKET INFO</th>
                                <th className="font-weight-bold text-italic text-dark text-right" style={{fontSize: 20}}>OUR FEES</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>{court.name} (${court.value})</td>
                                <td className="text-right">${court.value}</td>
                            </tr>

                            {form.accident === 'yes' ? (
                                <tr>
                                    <td>Accident</td>
                                    <td className="text-right">${additions.accident}</td>
                                </tr>
                            ) : null}
                            

                            {form.commercial === 'yes' ? (
                                <tr>
                                    <td>Commercial Ticket</td>
                                    <td className="text-right">${additions.commercial}</td>
                                </tr>

                            ) : null}
                            
                            {parseInt(form.misd_checked_number) > 0 ? (
                                <tr>
                                    <td>Misdemeanor ({form.misd_checked_number})</td>
                                    <td className="text-right">${calculateMisdemeanor(parseInt(form.misd_checked_number), additions)}</td>
                                </tr>
                            ) : null}
                            
                            {form.suspended_license === 'yes' ? (
                                <tr>
                                    <td>Suspended License</td>
                                    <td className="text-right">${additions.suspended_license}</td>
                                </tr>
                            ) : null}
                            
                            {form.reckless_driving === 'yes' ? (
                                <tr>
                                    <td>Reckless Driving</td>
                                    <td className="text-right">${additions.reckless_driving}</td>
                                </tr>
                            ) : null}
                           
                            {form.drunk_driving === 'yes' ? (
                                <tr>
                                    <td>Drunk Driving</td>
                                    <td className="text-right">${additions.drunk_driving}</td>
                                </tr>
                            ) : null}
                           
                            {form.drunk_driving_prior === 'yes' ? (
                                <tr>
                                    <td>Previous Drunk Driving</td>
                                    <td className="text-right">${additions.drunk_driving_prior}</td>
                                </tr>
                            ) : null}

                            
                            {discount ? (
                                <>
                                    <tr>
                                        <th className="font-weight-bold text-italic" style={{fontSize: 30}}>PRICE</th>
                                        <th className="font-weight-bold text-italic text-right" style={{fontSize: 30}}>{formatMoney(total)}</th>
                                    </tr>
                                     <tr>
                                        <th className="font-weight-bold text-italic" style={{fontSize: 30}}>DISCOUNT</th>
                                        <th className="font-weight-bold text-italic text-right" style={{fontSize: 30}}>-{formatMoney(discountAmount)}</th>
                                    </tr>
                                    <tr>
                                        <th className="text-dark-green font-weight-bold text-italic" style={{fontSize: 30}}>TOTAL</th>
                                        <th className="text-dark-green font-weight-bold text-italic text-right" style={{fontSize: 30}}>{formatMoney(discount)}</th>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <th className="text-dark-green font-weight-bold text-italic" style={{fontSize: 30}}>TOTAL</th>
                                    <th className="text-dark-green font-weight-bold text-italic text-right" style={{fontSize: 30}}>{formatMoney(total)}</th>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                </div>

                {discountAmount ? (
                    <p className="text-center text-success mb-0 font-weight-bold text-uppercase mt-4"><i className="fas fa-check-double " /> You've saved {formatMoney(discountAmount)}!</p>
                ) : null}

                {parseFloat(total) > 400 ? (
                    <Row className="mt-6 pay-buttons">
                        <Col lg={6} className="pt-3 pb-4 text-center align-self-center col-left">
                            <p className="text-sm" >PAY IN FULL</p>
                            <button className="btn btn-success" onClick={() => setView('upfront')}>PAY IN FULL</button>
                        </Col>
                        <Col lg={6} className="pt-3 pb-4 text-center align-self-center">
                            <p className="text-sm" >PAY OVER TIME</p>
                            <button className="btn btn-yellow" onClick={() => setView('over time')}>PAY OVER TIME</button>
                        </Col>
                    </Row>
                ) : (
                    <div lg={6} className="pt-3 pb-4 text-center align-self-center">
                        <p className="text-sm" >PAY IN FULL</p>
                        <button className="btn btn-success" onClick={() => setView('upfront')}>PAY IN FULL</button>
                    </div>
                )}
                
               

            </Container>

        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
        additions: state.form_settings.settings.additions,
        courts: state.form_settings.settings.courts
    };
};

export default connect(mapStateToProps, '')(Page4Overview);