/*
Documentation

compile and export all reducers for this system

*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import system from './reducers/system/system';
import device from './reducers/device/device';
import form from './reducers/form/form';
import form_settings from './reducers/form/form_settings';

const rootReducer = combineReducers({
    system,
    device,
    form,
    form_settings,
})

const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store
