//time variables used in this file

const oneDay = 86400;

//set month to be 30 days
const now = Math.floor(new Date() / 1000);
const week = oneDay * 7;
const month = oneDay * 30;

const onDayAgo = now - oneDay;
const oneWeekAgo = now - week;
const oneMonthAgo = now - month;

const onDayAhead = now + oneDay;
const oneWeekAhead = now + week;
const oneMonthAhead = now + month;

/*

This function takes a number such as 100 and turns it in to money
make sure to / 100 before sending numbers into this function if they are in cents
note: all system payments should be stored in cents with no decimal

*/

export const formatMoney = function (amount, decimalCount = 2, decimal = ".", thousands = ",") {
    
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = amount < 0 ? "-" : "";
    
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return '$' + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        
    } catch (e) {

        console.log(e)

    }

};

export const last24Hours = (payments, field) => calcPrevious(payments, field, onDayAgo)
export const last7Days = (payments, field) => calcPrevious(payments, field, oneWeekAgo)
export const last30Days = (payments, field) => calcPrevious(payments, field, oneMonthAgo)

export const next24Hours = (payments, field) => calcFuture(payments, field, onDayAhead)
export const next7Days = (payments, field) => calcFuture(payments, field, oneWeekAhead)
export const next30Days = (payments, field) => calcFuture(payments, field, oneMonthAhead)

/*
this function will calculate total amount of payments given a time frame

payments: array of payment object, we look for the amount property as well as a timestamp
field: the timestamp property to look for
time: the time to look before or after 

*/

const calcPrevious = (payments, field, time) => {

    let amount = 0;

    if(!payments || !payments.length) {
        return '$0'
    }


    payments = payments.filter(p =>  p[field] > time)

    payments.forEach(p => {
        amount = amount + p.amount;
    })

    return formatMoney(amount / 100)

}

/*
this function will calculate total amount of payments in the future

payments: array of payment object, we look for the amount property as well as a timestamp
field: the timestamp property to look for
time: the time to look before or after 

*/

const calcFuture = (payments, field, time) => {

    let amount = 0;

    if(!payments || !payments.length) {
        return '$0'
    }


    payments = payments.filter(p => p[field] > now &&  p[field] < time)

    payments.forEach(p => {
        amount = amount + p.amount;
    })

    return formatMoney(amount / 100)

}

export const passesDecimalCheck = (flat_fee_amount) => {

    const val = flat_fee_amount.split('.');

    if(val && val[1]) {
        
        if(val[1].length > 2) {
            return false;
        }

    }

    return true;

}