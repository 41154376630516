import * as actionTypes from 'store/actions';
import store from 'store';

// these should mirror the breakpoints in _variables_light.scss 
// in the assets/scss folder
const setBreakPoints = () => {

    const width = window.innerWidth

    return {
        isXS: width >= 0 ? true : false,
        isSM: width >= 576 ? true : false,
        isMC: width >= 768 ? true : false,
        isLG: width >= 1000 ? true : false,
        isXL: width >= 1280 ? true : false,
    }

} 

export const setDeviceListener = async() => {

    setDevice()

    window.addEventListener('resize', setDevice)

}

const setDevice = () => {

    store.dispatch({
        type: actionTypes.SET_DEVICE,
        payload: {
            device                : navigator.userAgent,
            currentScreenWidth    : window.innerWidth,
            currentScreenHeight   : window.innerHeight,
            ...setBreakPoints(),
        }
    });

}
