import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { Modal, FormGroup, Input } from "reactstrap";
import _pages from '_functions/pages'

const ModalDiscount = ({showModal, toggleModal, onAddDiscount}) => {

    const [code, setCode] = useState('');
    const [err, setErr] = useState(false);
    const [discount, setDiscount] = useState(false);

    const toggle = () => {
        toggleModal(false);
    }

    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("archk-discount-submit").click();
		}
	}

    const onApplyDiscount = async () => {

        if(discount) return;
        if(!code) setErr(true);

        const foundDiscount = await _pages.getDiscount(code);
        if(foundDiscount.data) {
            setErr(false);
            setDiscount(foundDiscount.data)
            onAddDiscount(foundDiscount.data)
        } else {
            setErr(true)
        }

    }

    useEffect(() => {

        if(showModal) {
            const focusInput = (retries = 0) => {
                if(retries > 5) return;
                const input = document.getElementById('archk-discount-input')
                if(input) {
                    input.focus();
                } else {
                    setTimeout(() => focusInput(retries + 1), 100)
                }
            }
            focusInput();
        }

    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggle}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Referral Code</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggle}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Enter your referral code here.</label>
                    <Input 
                        id="archk-discount-input"
                        onKeyDown={_handleKeyDown}
                        type="text"
                        value={code}
                        disabled={discount}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </FormGroup>

            </div>

            {err ? (
                <div className="modal-body bg-secondary">
                    <p className="text-sm font-weight-bold text-warning mb-0">The referral code you have provided is invalid.</p>
                </div>
            ) : null}
            {discount ? (
                <div className="modal-footer d-block" onClick={toggle}>
                    <div className="alert alert-success mb-0 cursor-pointer"><i className="fas fa-check mr-2 " />Your referral code was accepted for {discount}% Off!</div>
                </div>
            ) : (
                <div className="modal-footer text-right">
                    <button className="btn btn-yellow" onClick={onApplyDiscount} id="archk-discount-submit" disabled={discount}>
                        Apply Code
                    </button>
                </div>
            )}

        </Modal>

    )
}

ModalDiscount.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalDiscount