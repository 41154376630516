import * as actionTypes from 'store/actions';
import store from 'store';

export const setFormSettings = (data) => {

    store.dispatch({
        type: actionTypes.SET_FORM_SETTINGS,
        payload: data
    });

}
