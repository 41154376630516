import LOGO_DARK from 'assets/img/brand/LogoDark.png'
import LOGO_YELLOW from 'assets/img/brand/LogoYellow.png'

let keys = {
    LOGO_DARK,
    LOGO_YELLOW,
    COMPANY: 'Roadside Attorneys',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'roadside.fairmaxlaw.com') {

    keys = {

        ...keys,
        
        API_URL                   : 'https://api.roadside.fairmaxlaw.com',
        SYSTEM_API_KEY            : 'architeck-',
    }

//using staging keys, currently no staging environment set up
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        API_URL                   : 'https://staging.api.zapreports.com',
        SYSTEM_API_KEY            : 'architeck-',

    }

//using development keys
} else {

    keys = {

        ...keys,

        API_URL                   : 'http://localhost:5002',
        SYSTEM_API_KEY            : 'architeck-',

    }

}

export default keys
