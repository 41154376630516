/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    form: {
        _id                               : '',
        contact_id                        : '',
        given_name                        : '',
        family_name                       : '',
        email                             : '',
        phone                             : '',
        file_name                         : '',
        file_uploaded                     : 'false',
        ticket_number                     : '',
        date_issued                       : '',
        court                             : '',
        accident                          : 'false',
        commercial                        : 'false',
        misd_checked                      : 'false',
        misd_checked_number               : 'false',
        suspended_license                 : 'false',
        suspended_license_knowledge       : 'false',
        suspended_license_reinstatement   : 'false',
        cited_for_no_insurance            : 'false',
        had_valid_insurance               : 'false',
        had_valid_insurance_in_car        : 'false',
        reckless_driving                  : 'false',
        drunk_driving                     : 'false',
        drunk_driving_prior               : 'false',
        discount: 0,
        discount_amount: 0
    },
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_FORM:

            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload
                },
            }

            default:
                return state;

    }

}

export default reducer;
