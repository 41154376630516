// 3736515

import keys from 'keys';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Input, FormGroup } from 'reactstrap';

import { setProgress } from 'store/functions/system/system';
import { toggleStandardLoader } from 'store/functions/system/system';
import { setForm } from 'store/functions/form/form';

const requiredFields = ['ticket_number', 'date_issued', 'court']

const Page2 = ({form, courts}) => {

    const [apiError, setApiError] = useState(false);
    const [redirect, setRedirect] = useState(form.contact_id ? null : '/1');
    const [imagePreview, setImagePreview] = useState(null);
    const [image, setImage] = useState({ name: form.file_name });
    const [state, setState] = useState({
        ticket_number   : form.ticket_number,
        date_issued     : form.date_issued,
        court           : form.court,
    });
    const [errors, setErrors] = useState([])


    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("archk-page-2-submit").click();
		}
	}

    const onInputChange = (field, e, value) => {
        const newState = JSON.parse(JSON.stringify(state));

        if(value) {
            newState[field] = value;
        } else {
            newState[field] = e.target.value;
        }

        setState(newState)
    }

    const getErr = (err) => {
        return errors.includes(err) ?  <label className="text-danger">Required *</label> : null
    }

   
    const onSubmit = async () => {
        const errs = [];

        requiredFields.forEach(field => {
            if(!state[field]) errs.push(field)
        })

        if(!image.name) errs.push('image');

        setErrors(errs);

        if(errs.length) return;

        var formData = new FormData();
        var imageFile = document.querySelector('#file-upload');

        formData.append("file", imageFile.files[0]);
        formData.append("ticket_number", state.ticket_number);
        formData.append("date_issued", state.date_issued);
        formData.append("court", state.court);
        formData.append("contact", form.contact_id);

        try {
            toggleStandardLoader(true);
            const saved = await Axios.post(`${keys.API_URL}/v1/page/2`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': `Bearer ${keys.SYSTEM_API_KEY}`
                }
            })
            toggleStandardLoader(false);

            if(saved.data && saved.data.data && saved.data.success) {

                setForm({...state, form: saved.data.data._id})
                return setRedirect('/3')
            }

            return setApiError(true);

        } catch(e) {
            console.log(e)
            toggleStandardLoader(false);
            return setApiError(true);
        }
    }

    const onImageChange = (e, a) => {

        if(e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
            let reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        }

    }

    useEffect(() => {
        setProgress({page: 2, percent: 25})
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div id="page-2" className="page py-4 py-md-6">
            <Container>

                <div className="alert alert-info mb-4 mb-md-6"><i className="fas fa-tachometer-alt mr-2 " />
                    Traffic violations can <b className="text-underline">dramatically</b> increase your insurance premiums.
                </div>

               <Row>
                    <Col lg={6}>
                        <h1 className="mb-4 mb-md-6">Upload Ticket</h1>

                        <FormGroup>
                            {getErr('image')}

                            <div>
                                <label className="upload-input d-inline-block" htmlFor="file-upload" style={{width: 600, maxWidth: '100%'}}> 
                                    <i className="fas fa-upload mr-3 " />
                                    CLICK HERE TO UPLOAD A PICTURE OF YOUR TICKET
                                </label>
                                <input onChange={onImageChange} accept="image/*"  type="file" id="file-upload" className="d-none" />
                            </div>

                            {image && image.name ? (
                                <div className="pb-6">
                                    <div className="border p-4 bg-white my-3 rounded" style={{width: 600, maxWidth: '100%'}}>
                                        Selected File:{' '}
                                        <b className="font-weight-bold text-dark">{image.name}</b>
                                    </div>
                                    {imagePreview ? (
                                        <img className="z-depth-1 rounded" src={imagePreview} style={{width: 600, maxWidth: '100%'}} alt="upload" />
                                    ) : null}
                                </div>
                            ) : (
                                <p className="mt-4">No File Selected</p>
                            )}

                        </FormGroup>
                    </Col>

                    <Col lg={6}>

                        <h1 className="mb-4 mb-md-6">Ticket Info</h1>

                        <FormGroup>
                            {getErr('ticket_number')}
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={state.ticket_number}
                                onChange={(e) => onInputChange('ticket_number', e)}
                                placeholder="Ticket Number"
                            />
                        </FormGroup>

                        <FormGroup>
                            {getErr('date_issued')}
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="date"
                                value={state.date_issued}
                                onChange={(e) => onInputChange('date_issued', e)}
                                placeholder="Date Ticket Was Issued"
                            />
                        </FormGroup>

                        <FormGroup>
                            {getErr('court')}
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="select"
                                value={state.court}
                                onChange={(e) => onInputChange('court', e)}
                                placeholder="Court Listed On Ticket"
                            >
                                <option value="false">Court Listed On Ticket</option>
                                {courts.map((court, i) => (
                                    <option key={i} value={court.name}>{`${court.name} - ($${court.value})`}</option>
                                ))}
                            </Input>    
                        </FormGroup>

                        <hr />

                        {apiError ? (
                            <div className="alert alert-warning">Please try again in a few minutes</div>
                        ) : null}

                        <div className="text-right">
                            <button onClick={onSubmit} className="btn btn-yellow" id="archk-page-2-submit">SUBMIT AND CONTINUE</button>
                        </div>

                    </Col>

                </Row>

            </Container>
         
        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
        courts: state.form_settings.settings.courts
    };
};

export default connect(mapStateToProps, '')(Page2);