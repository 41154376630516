/*
Documentation

this route handles all caught errors on our app

*/

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import routes from "./routes";

class Errors extends React.Component {

    //get routes for this layout
    getRoutes = routes => routes.map((prop, key) => {

        //recursive to get the dropdown routes
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }

        return (
            <Route 
                exact path={`/errors${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })
   
   
    render() {
        
        return (
            <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/errors/standard" />
            </Switch>

        );
    }
}
  
export default Errors