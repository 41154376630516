import { Container } from 'reactstrap';

const ContractOhio = () => {

    return (
        <div id="page-1" className="page py-4 py-md-6">
            <Container>

            <h2 className="mb-6">REPRESENTATION AGREEMENT FOR TRAFFIC TICKET/MATTER DEFENSE</h2>
 
            <p className="mb-6">__________________________, (hereafter “Client”) retains and employs Roadside Attorneys, (hereinafter “Attorneys”) to represent Client as legal counsel (hereafter “Services”) in connection with a traffic ticket and under the following terms:</p>

            <ol>
                <li>Attorneys agree to represent Client in defense of the matter noted above. For purposes of this Agreement, the terms ‘matter, ticket, or ticket/matter are used interchangeably but are one in the same. </li>
                <li>Client agrees to fully cooperate with Attorneys including but not limited to taking phone calls, returning phone calls, and monitoring emails and text messages. </li>
                <li>No representation has been made to the Client concerning the probability of success as to their case. The Client also acknowledges and understands that she/he retained Attorneys and no representations have been made regarding which lawyer will represent her/him. Further, Client agrees that Attorneys may substitute a lawyer not associated with the firm to represent the Client but will notify client of same in advance of a hearing; if client refuses, Attorney may elect to withdraw from the representation (with permission of the court). The Fee will not change if such a lawyer is substituted in for Attorneys. </li>
                <li>Client understands and is aware that he/she is submitting this ticket to attorney through Off The Record and as such, is authorizing all employees of Off The Record to view the text conversations between Client and Attorney; and authorizes Attorney to communicate with the employees of Off The Record about Clients case and/or the Services. </li>
                <li>Client agrees that the representation ends when the proceedings concerning the Ticket/Matter noted above are concluded. </li>
                <li>In any matter, a person who receives a traffic citation or any charge and is represented by counsel, that person does not have to appear if the person has given counsel authority to settle the dispute and the court allows it. Client authorizes Attorneys to settle the violation/ticket for which the Ticket/Matter noted above was written including entering a plea of responsible, guilty, or any other disposition. Attorney may also settle the matter by entering a plea of responsible/guilty on Client’s behalf for any citation that is equal to or lesser than the original citation. Client further authorizes Attorneys to write letters on clients behalf to the court or municipality that issued the citation admitting Client’s responsibility but requesting a reduction in the points and only assessing fines to Client. That would be in lieu of attending a court hearing. </li>
                <li>Client agrees and understands that notices about the matter will be sent only via the email provided above or via text message through the app, and that Client is responsible for checking their email daily until the representation is concluded. Client understands that important messages about Court FINES and COSTS, due dates, and court hearings are going to be sent to the email. If Client does not have an email, then Client must call the office once per week in order to see the status of the matter. Furthermore, Client hereby gives Attorney’s permission to text them on the number they provided, and that a text message shall be considered the same as an email for notice purposes. </li>
                <li>If Attorney places a bond with the court, Client authorizes Attorney to process those fees on Clients card. </li>
                <li>If Client attempts to retain Attorneys within 72 hours of a hearing & Attorneys accept the case, Attorneys may not be able to cover the hearing due to the short notice and will work to reschedule the hearing. </li>
                <li>Client agrees that his fee is earned upon receipt and will not be deposited in the attorney’s IOLTA account   (“FEE”).</li>
                <ol>
                    <li>Refunds: All monies paid to us are non-refundable and earned upon receipt unless we are unable to complete the representation for any reason, in which case Client may be entitled to a refund of all or part of the fees paid based upon the value of services rendered.</li>
                    <li>A Court will almost always impose COSTS and FINES. Any COSTS and FINES imposed by the Court are separate and NOT covered by the FEE. Client will need to pay these to the Court within one (1) week of when they are imposed, or sooner if the court demands. The COSTS and FINES vary. Attorneys will update the website and email the COSTS and FINES by the end of the day when your Ticket/Matter is set for hearing. Client is responsible to check the email provided above and/or the website for updates and information.  </li>
                    <li>The FEE does not cover any appeal. If Client wishes Attorneys to provide representation in an appeal, a new a separate agreement and fee arrangement will have to be negotiated. </li>
                </ol>
                <li>The Client understands that should she/he appear on the date of the hearing on the Ticket/Matter noted above and if her/his lawyer is in another courtroom at the time of the hearing, then Client is to wait for her/his lawyer.</li>
                <li>It is the sole responsibility of the Client to keep Attorneys informed of any changes to personal information, including, but not limited to address, cell phone number & email address. Any financial liabilities incurred due to the Client failing to inform Attorneys of such changes, are the sole responsibility of the Client. </li>
                <li>You represent and warrant that you have the right to use any credit card that you submit in connection with a transaction for the Services. By submitting such information, you grant to us the right to provide such information to third parties for purposes of facilitating the provision of the Services. Verification of information may be required prior to the acknowledgment or completion of any payment transaction. By submitting such information, you authorize ATTORNEYS to charge you the price from the quotation for the Services.</li>
                <li>We reserve the right to decline any case for any reason. </li>
                <li> You represent and warrant that all information that you provide to us on or through the ATTORNEYS Properties will be truthful and accurate.</li>
                <li>Client acknowledges she/he has read this contract, received a copy, and agrees to its terms and conditions. There are no other agreements, oral or otherwise, between Client and Attorneys.</li>
            </ol>

            <p className="text-sm">1:   All requests for representation are reviewable for conflicts of interest. If there is a conflict, then the fee will be returned. </p>
  
            </Container>
         
        </div>
    )

}

export default ContractOhio;