import React, { Component } from 'react';
import { WaveSpinner } from "react-spinners-kit";
import { connect } from 'react-redux';

class Loader extends Component {


    state = {
        showLoader: false,
        faderOpacity: 1,
    };

    fade = () => {
        const x = setInterval(() => {
            this.setState({faderOpacity: this.state.faderOpacity - .1});
    
            if(this.state.faderOpacity - .1 <= 0) {
                clearInterval(x);
                this.setState({showLoader: this.props.showLoader, faderOpacity: 1})
            }
        }, 100)
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        if(nextProps.loader !== this.props.loader) {

            if(nextProps.loader === true) {

                //this is used to hold of on loading for a fraction of a second
                //if we set this to not load in that time don't bother showing the loader
                this.setState({shouldShow: true})
                setTimeout(() => {
                    if(this.state.shouldShow) {
                        this.setState({showLoader: nextProps.loader})
                    }
                }, 150)
            }
    
            if(this.props.loader === true && nextProps.loader === false) {
                this.setState({shouldShow: false})
                this.fade()
            }

        }

    }

    render() {

        if(this.state.showLoader) {

            return (
                <div 
                    id="AosFader"
                    className="top-0 bottom-0 right-0 left-0 position-fixed d-flex bg-white"
                    style={{ opacity: this.state.faderOpacity, zIndex: 9999999999999 }}
                >
                    <div className="align-self-center ml-auto mr-auto">
                        <WaveSpinner size={30} color="#fb6340" loading={true}/>
                    </div>
                </div> 
            )

        } else {

            return <div></div>

        }

    }

}

const mapStateToProps = state => {
    return {
        loader: state.system.loader,
    };
};
  
export default connect(mapStateToProps, '')(Loader);