import { Container, Row, Col } from 'reactstrap'
import keys from 'keys';

const number = '(313) 209-9888'

const Header = () => {

    return (
        <div className="bg-white roadside-header py-5">
            <Container>
                <Row>
                    <Col md={6} className="align-self-center">
                        <img src={keys.LOGO_DARK} style={{maxWidth: 150}} alt="Roadside Attorneys" />
                        <span className="text-sm ml-5 mb-0 font-weight-bold">Over 40 years of experience</span>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        <a href={`tel:${number}`}>
                            <h4 className="display-4 text-dark phone-number">
                                <i className="fas fa-phone mr-4 text-yellow" /> {number}
                            </h4>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Header;