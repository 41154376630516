import { Container, Progress } from 'reactstrap'
import { connect } from 'react-redux';

const MasterProgress = ({progress}) => {

    let title = '1. Your Information';
    if(progress.page === 2) title = '2. Upload Ticket'
    if(progress.page === 3) title = '3. Describe Your Ticket'
    if(progress.page === 4) title = '4. Terms Of Service'
    if(progress.page === 5) title = '5. Payment Information'
    if(progress.page === 6) title = 'You\'re All Set!'

    return (
        <div className="master-progress">
            <Container fluid>
                <div className="progress-wrapper">
                    <div className="progress-info">
                        <div className="progress-label">
                            <span>{title}</span>
                        </div>
                        <div className="progress-percentage">
                            <span>{progress.percent}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={progress.percent} color="default" />
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        progress: state.system.progress,
    };
};

export default connect(mapStateToProps, '')(MasterProgress);