// 3736515

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Input, FormGroup } from 'reactstrap';

import { setProgress } from 'store/functions/system/system';
import { setForm } from 'store/functions/form/form';
import { toggleStandardLoader } from 'store/functions/system/system';

import _pages from '_functions/pages'
import Cookies from 'universal-cookie';

const Lookup = ({form}) => {

    const [redirect, setRedirect] = useState(form.contact_id ? null : '/1');
    const [ticketNumber, setTicketNumber] = useState(form.ticket_number);

    const onSubmit = async () => {

        if(!ticketNumber) return;

        toggleStandardLoader(true);
        const data = await _pages.lookup(form.contact_id, ticketNumber)
        toggleStandardLoader(false);

        const cookies = new Cookies();
        
        if(data.data) {
            setForm(data.data);
        } else {
            setForm({ticket_number: ticketNumber})
        }

        cookies.set('ticket_number', ticketNumber)


        setRedirect('/2')

    
    }

    useEffect(() => {
        window.gtag('event', 'conversion', {'send_to': 'AW-302739913/KxGKCP-92PMCEMnjrZAB'});
        setProgress({page: 'lookup', percent: 20})
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div id="page-2" className="page py-4 py-md-6">
            <Container>

                <div className="alert alert-warning mb-4 mb-md-6"><i className="fas fa-hashtag mr-2 " />
                    In most cases your ticket number can be found on the <b className="text-underline">top of your ticket</b>.
                </div>

                <h1 className="mb-4 mb-md-6">Please Enter Your Ticket Number</h1>

                <FormGroup>
                    {/* {getErr('ticket_number')} */}
                    <Input 
                        type="text"
                        value={ticketNumber}
                        onChange={(e) => setTicketNumber(e.target.value)}
                        placeholder="Ticket Number"
                    />
                </FormGroup>

                <hr />

                <div className="text-right">
                    <button className="btn btn-yellow" onClick={onSubmit}>SUBMIT AND CONTINUE</button>
                </div>

            </Container>
         
        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
    };
};

export default connect(mapStateToProps, '')(Lookup);