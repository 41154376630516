import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";

import Header from './components/Header'
import Progress from './components/Progress'
import Disclaimer from './components/Disclaimer'

import Cookies from 'universal-cookie';

import { setForm } from 'store/functions/form/form';
import { setFormSettings } from 'store/functions/form/form_settings';

import _pages from '_functions/pages';

 class Flow extends Component {

    state = {
        loaded: false
    }

    componentDidUpdate(e) {
        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            if(this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }
        }
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {

            return (
                <Route
                    path={`${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };

    componentDidCatch = (e) => {
        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }
    }

    componentDidMount = async () => {

        window.addEventListener('load', (event) => {

            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            window.gtag = gtag;
            window.gtag('js', new Date());
            window.gtag('config', 'AW-302739913');

        })

        const cookies = new Cookies();

        // clear cookie catch, used for development
        // cookies.remove('contact_id');
        // cookies.remove('ticket_number');
 
        const contact_id = cookies.get('contact_id');
        const ticket_number = cookies.get('ticket_number');

        const settings = await _pages.getSettings();
        setFormSettings(settings.data)

        if(contact_id && ticket_number) {
            const values = await Promise.all([
                await _pages.findContact(contact_id),
                await _pages.lookup(contact_id, ticket_number),
            ])

            if(values[0].data && values[1].data) {
                setForm({...values[1].data, ...values[0].data, contact_id: values[0].data._id, form: values[1].data._id})
            } else if(values[0].data) {
                setForm({...values[0].data, contact_id: values[0].data._id, ticket_number: ticket_number ? ticket_number : ''})
            }

        } else if(contact_id) {
            const contact = await _pages.findContact(contact_id);
            if(contact.data) setForm({...contact.data, contact_id: contact.data._id})
        }

        this.setState({loaded: true})

    }

    render() {

        const { loaded, shouldRedirect } = this.state;

        if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!loaded) return <div />

        return (
            <div className="main-conten" ref="mainContent">
                <Header />
                <Switch>{this.getRoutes(routes)}</Switch>
                <Disclaimer />
                <Progress />
            </div>
        );
    }
}

export default Flow

