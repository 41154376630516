import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { Container, Row, Col, Input, FormGroup } from 'reactstrap';

import { setProgress } from 'store/functions/system/system';
import { setForm } from 'store/functions/form/form';
import { toggleStandardLoader } from 'store/functions/system/system';

import _pages from '_functions/pages';
import Cookies from 'universal-cookie';

const requiredFields = ['given_name', 'family_name', 'email', 'phone']

const Page1 = ({form}) => {

    const [redirect, setRedirect] = useState(false);
    const [state, setState] = useState({
        given_name: form.given_name,
        family_name: form.family_name,
        email: form.email,
        phone: form.phone,
    });
    const [errors, setErrors] = useState([]);
    const [apiError, setApiError] = useState('');

    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("archk-page-1-submit").click();
		}
	}

    const onInputChange = (field, e, value) => {
        const newState = JSON.parse(JSON.stringify(state));

        if(value) {
            newState[field] = value;
        } else {
            newState[field] = e.target.value;
        }

        setState(newState)
    }

    const getErr = (err) => {
        return errors.includes(err) ?  <label className="text-danger">Required *</label> : null
    }

    const onSubmit = async () => {

        const errs = [];

        requiredFields.forEach(field => {
            if(!state[field]) errs.push(field)
        })

        setErrors(errs);

        if(errs.length) return;

        toggleStandardLoader(true);
        const saved = await _pages.savePage1(state);
        toggleStandardLoader(false);

        if(!saved.success) {
            return setApiError(saved.message);
        }

        if(!saved.data._id) {
            return setApiError(`Please try again in a few minutes`);
        }

        const cookies = new Cookies();
        cookies.set('contact_id', saved.data._id)

        setForm({...state, contact_id: saved.data._id})
        setRedirect('/lookup')

    }

    useEffect(() => {
        setProgress({page: 1, percent: 1})
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div id="page-1" className="page py-4 py-md-6">
            <Container>

                <div className="alert alert-success mb-4 mb-md-6"><i className="fas fa-info-circle mr-2 " />
                    Every year 1,000's of people get their traffic tickets dismissed. Let's fight your ticket.
                </div>

                <h1 className="mb-4 mb-md-6">Get Started Fighting Your Ticket</h1>

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            {getErr('given_name')}
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={state.given_name}
                                onChange={(e) => onInputChange('given_name', e)}
                                placeholder="First Name"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            {getErr('family_name')}
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={state.family_name}
                                onChange={(e) => onInputChange('family_name', e)}
                                placeholder="Last Name"
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    {getErr('email')}
                    <Input 
                        onKeyDown={_handleKeyDown}
                        type="text"
                        value={state.email}
                        onChange={(e) => onInputChange('email', e)}
                        placeholder="Email"
                    />
                </FormGroup>

                <FormGroup>
                    {getErr('phone')}
                    <Input 
    					onKeyDown={_handleKeyDown}
                        type="text"
                        value={state.phone}
                        onChange={(e) => onInputChange('phone', e)}
                        placeholder="Phone"
                    />
                </FormGroup>

                {apiError ? (
                    <div className="alert alert-warning">{apiError}</div>
                ) : null}

                <div className="text-right">
                    <button onClick={onSubmit} id="archk-page-1-submit" className="btn btn-yellow">SUBMIT AND CONTINUE</button>
                </div>

            </Container>
         
        </div>
    )

}

const mapStateToProps = state => {
    return {
        form: state.form.form,
    };
};

export default connect(mapStateToProps, '')(Page1);