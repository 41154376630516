import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import moment from 'moment';
import { formatMoney } from 'utils/currency';
import { toggleStandardLoader } from 'store/functions/system/system';

import { Container } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'

import _pages from '_functions/pages';

const Upfront = ({finalCost, paymentErr, paymentLoaded, payformUrl, view, form, onPaymentErr}) => {

    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [type, setType] = useState(view !== 'upfront' ? 'over time' : 'upfront');
    const [redirect, setRedirect] = useState(false);
    const [err, setErr] = useState(false)

    const receiveZeamsterMessage = useCallback(async (event) => {

        //if the origin is from zeamster add new card to state
        // if (event.origin === keys.ZEAMSTER_ORIGIN) {
        if (payformUrl && payformUrl.includes(event.origin)) {

            const card = JSON.parse(event.data);

            window.scrollTo(0, 0);
            toggleStandardLoader('bg-white')
    
            const saved = await _pages.savePage5({
                transaction_amount: parseFloat(finalCost),
                account_vault_api_id: card.account_vault_api_id,
                type: type,
                form: form.form
            })
        
            if(saved.success) {
                setRedirect('/confirmation')
                toggleStandardLoader(false)
            } else {
                setErr(true)
                onPaymentErr()
            }
    
        }

    }, [payformUrl, finalCost, form.form, type, onPaymentErr])

    useEffect(() => {
        window.addEventListener("message", receiveZeamsterMessage, false)
        return () => {
            window.removeEventListener("message", receiveZeamsterMessage)
        }
    }, [receiveZeamsterMessage])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Container>

            {err ? (
                <div className="alert alert-danger">An Error Occurred Processing Your Payment. Please Try Again.</div>
            ) : null}

            {type === 'upfront' ? (
                <h1 className="display-1">Defend My Ticket For: <span className="d-inline text-success">{formatMoney(finalCost)}</span></h1>
            ) : (
                <div>
                    <h1 className="display-1">Defend My Ticket For:</h1>
                    <div className="table-responsive">
                        <table className="table mt-4">
                            <tbody>
                                <tr>
                                    <td style={styles.td} className="text-success font-weight-bold">Due Today:</td>
                                    <td style={styles.td} className="text-right text-success font-weight-bold">{formatMoney(parseFloat(finalCost) / 2)}</td>
                                </tr>
                                <tr>
                                    <td style={styles.td} className="font-weight-bold">Due On: {moment().add(1, 'month').format('DD/YY')}</td>
                                    <td style={styles.td} className="text-right font-weight-bold">{formatMoney(parseFloat(finalCost) / 2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <p className="mb-0">** Your card will not be charged unless we accept your ticket.</p>

            <hr />

            {type === 'upfront' && parseInt(finalCost) > 400 ? (
                <button className="btn btn-yellow mb-7 mt-5" onClick={() => setType('over time')}>I'D RATHER PAY OVER TIME</button>
            )  : type !== 'upfront' ?  (
                <button className="btn btn-yellow mb-7 mt-5" onClick={() => setType('upfront')}>I'D RATHER PAY IN FULL</button>
            ) : null}

            <h1 className="display-1">Payment Info</h1>
            <h4 className="display-3 mb-6">Credit Card Information</h4>

            {paymentErr ? (
                <div className="alert alert-danger">Something's not right, please try again in a few minutes.</div>
            ) : !paymentLoaded || !iframeLoaded ? (
                <Circle />
            ): null}

            <iframe
                id="payment-form"
                onLoad={() => setIframeLoaded(true)}
                title="fortispay"

                width="100%"
                style={{border: 'none', margin: 0, display: iframeLoaded ? 'block' : 'none'}}
                src={payformUrl ? payformUrl : 'false'}
            />

            <p className="text-center">(This Card <span className="text-success text-underline font-weight-bold">Will Not</span> Be Charged Upon Finishing Submission.)</p>

        </Container>
    )

}

const styles = {
    td: {
        borderTop: 'none',
        borderBottom: 'none'
    }
}

const mapStateToProps = state => {
    return {
        form: state.form.form,
    };
};

export default connect(mapStateToProps, '')(Upfront);