import React from 'react'

const LoadingCircle = (props) => {

    return (
        <div className="text-center" style={props.style ? props.style : {}}>
            <div className="lds-ring"><div /><div /><div /><div /></div>
        </div>
    );

}

export default LoadingCircle;