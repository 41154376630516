import { Container, Row, Col } from 'reactstrap'
import keys from 'keys'
import moment from 'moment';

import SuperLawyers from 'assets/img/brand/SuperLawyers.png'

const Disclaimer = () => {

    return (
        <div className="disclaimer py-9">
            <Container>

                <div className="mb-6">
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <img style={{maxWidth: 250}} src={keys.LOGO_YELLOW} alt="Roadside" />
                        </Col>
                        <Col lg={6} className="align-self-center text-white">
                            &copy; {moment().format('YYYY')} ROADSIDE ATTORNEYS. ALL RIGHTS RESERVED.
                        </Col>
                    </Row>
                </div>

                <p className="footer-disclaimer">All legal services are provided by in house employees or affiliated and related entities. Please contact us if you have specific questions on a state or entity.</p>
                <p className="footer-disclaimer">Jaafar Law Group PLLC, a Michigan Professional Limited Liability Company d/b/a Roadside Attorneys registered in MI; Jaafar Law Group PLLC, a Michigan Professional Limited Liability Company d/b/a Jaafar Law Group PLLC in OH.</p>
                <p className="footer-disclaimer">PAID ATTORNEY ADVERTISEMENT: Roadside Attorneys, a service of Jaafar Law Group PLLC is a Law firm. This website is an advertisement. It is not a lawyer referral service or prepaid legal services plan. Each of the lawyers on this site are either Managers, Members, Partners, Of Counsel, Limited Partners or staff attorneys. All photos are of models and do not depict clients. An attorney responsible for the content of this Site is Michael Jaafar, Esq., licensed in Michigan with offices at 1 Parklane Blvd, Suite 729 East, Dearborn, MI 48126. To see the attorney in your area who is responsible for this advertisement, please click here. David Ienna is the Chief Executive of the firm in all states. Prior results do not guarantee a similar outcome.</p>
                <p className="footer-disclaimer">If you live in Alabama, Florida, Missouri, New York or Wyoming, please click here for additional information.</p>
                <p className="footer-disclaimer">By an Act of Congress and the President of the United States, Roadside Attorneys, a service of Jaafar Law Group PLLC is a federally designated Debt Relief Agency. Attorneys and/or law firms promoted through this website are also federally designated Debt Relief Agencies. They help people file for relief under the U.S. Bankruptcy Code. Disclosures Required Under the U.S. Bankruptcy Code.</p>
                <p className="footer-disclaimer">Zero Point Pledge: The goal is to avoid points being assessed to a Client’s driving record at the conclusion of the matter. Of course, no attorney can guarantee success. But, as proof of our commitment to reaching the goal, Attorneys promise to pay Client who meets the conditions listed below $200 if Client receives any points on their license from the ticket that Client hired Attorney to fight. This promise is made to only to a Client who meets all of the following conditions: a) Client has no traffic tickets in past 4 years; b) Client did not exceed 15 miles over the posted speed limit; c) Client was not issued a ticket in a school/construction zone; d) Client was not involved in an accident; e) Client’s ticket was not issued in any cities that fall within the 15th District Court or 19th District Court; f) Client’s ticket does not contain more than one offense charged on it. In other words, while we cannot guarantee a result, we are confident in our ability to represent you and to reach the goal of no points being assessed against your license if you are and have been a good driver, notwithstanding the current speeding ticket you have been issued.</p>
                <p className="footer-disclaimer">By submitting, you agree the phone number you provided may be used to contact you by the law firm and its affiliates (including autodialed, pre-recorded calls or text messages). You can opt out any time.</p>

                <div className="text-center">
                    <img src={SuperLawyers} style={{maxWidth: 200}} alt="Super Lawyers Mike Jaafar" />
                </div>

            </Container>
        </div>
    )
}

export default Disclaimer;