/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { setDeviceListener } from 'store/functions/device/device'

const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);

    // listen for changes in screen size, set device, etc
    setDeviceListener()

    return resolve(true)

})

export default init