import { connect } from 'react-redux';
import { calculateMisdemeanor } from '../_calculations';

const Addition = ({field, state, additions}) => {
    let value = additions[field];

    if(field === 'misd_checked') {
        value = calculateMisdemeanor(state.misd_checked_number, additions)
        if(!value) return ''
    }

    return state[field] === 'yes' ? <p className="mt-6">Add: <span className="text-success">${value}</span></p> : ''
}


const mapStateToProps = state => {
    return {
        additions: state.form_settings.settings.additions,
    };
};

export default connect(mapStateToProps, '')(Addition);